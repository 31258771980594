"use client";
import React from "react";
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { usePathname } from "next/navigation";
import { capitalizeFirstLetter } from "utils/utils";
import { Home } from "@mui/icons-material";
import Link from "modules/common/components/navigation/Link";
import { Theme } from "@mui/system";

interface Props {
  name?: string;
  sx?: any;
  hardCodeDisable?: {
    [key: number]: boolean;
  };
  names?: {
    [key: number]: string;
  };
  shift?: boolean;
}

const generatePathNames = (pathName: string, shift?: boolean): string[] => {
  const paths = pathName.split("?")[0].split("/").filter(Boolean);
  if (shift) paths.splice(2, 1);
  return paths;
};

const formatPathName = (path: string): string =>
  capitalizeFirstLetter(path.replace(/-/g, " "));

export const Breadcrumbs = ({
  shift,
  names,
  sx,
  name,
  hardCodeDisable,
}: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const pathName = usePathname();
  const paths = generatePathNames(pathName, shift);

  const displayPaths = isMobile && paths.length > 2 ? paths.slice(-2) : paths;

  const generateLink = (idx: number) => {
    if (isMobile && paths.length > 2) {
      if (idx === 0) return `/${paths.slice(0, -1).join("/")}`;
      if (idx === 1) return `/${paths.join("/")}`;
    }
    return `/${paths.slice(0, idx + 1).join("/")}`;
  };

  return (
    <MuiBreadcrumbs sx={{ ...sx }}>
      <Link href="/" title="Home">
        <Tooltip title="Home" placement="bottom">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Home fontSize="small" />
          </Box>
        </Tooltip>
      </Link>
      {displayPaths.map((path, idx) => {
        const actualIdx = paths.length - displayPaths.length + idx;
        const isLast = idx === displayPaths.length - 1;
        const displayText = formatPathName(names?.[actualIdx] || name || path);
        const disableLink = isLast || hardCodeDisable?.[actualIdx];

        return disableLink ? (
          <Typography key={actualIdx} color="text.primary">
            {displayText}
          </Typography>
        ) : (
          <Link key={actualIdx} href={generateLink(idx)}>
            {displayText}
          </Link>
        );
      })}
    </MuiBreadcrumbs>
  );
};
